/* Global Styles */
body, html {
  margin: 0;
  height: 100%;
  overflow: hidden; /* Prevent body overflow */
}

/* Login Page Styles */
.login-page {
  display: flex;
  min-height: 100vh;
  background-image: url('../images/login_board.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-form-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem; /* Add some padding for mobile */
}

.login-box {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px; /* Default width for larger screens */
  max-width: 100%; /* Ensure it doesn’t exceed viewport width */
  border-radius: var(--border-radius);
  text-align: center;
}

/* Form Fields */
input[type="email"], 
input[type="password"], 
input[type="text"] {
  width: 100%;
  padding: 0.75rem; /* Adjust padding for consistency */
  border: 1px solid #ccc; /* Light grey border */
  background-color: #f9f9f9; /* Light grey background */
  box-sizing: border-box;
  margin-bottom: 1rem; /* Space between fields */
  border-radius: var(--border-radius);
  font-size: 1rem; /* Consistent font size */
}

/* Buttons */
button {
  background-color: #007bff; /* Primary color */
  color: white;
  border: none;
  border-radius: var(--border-radius);
  padding: 0.75rem;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
}

button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Error and Success Messages */
.error-message,
.success-message {
  margin-bottom: 1rem;
  font-size: 0.875rem; /* Slightly smaller font size */
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .login-box {
    width: 90%; /* Wider width for smaller screens */
    padding: 1.5rem; /* Add padding to prevent content from touching edges */
    max-height: 90vh; /* Limit the height to avoid overflowing the viewport */
    overflow-y: auto; /* Allow scrolling if content exceeds the height */
  }

  /* Ensure that input fields remain visible and manageable */
  input[type="email"], 
  input[type="password"], 
  input[type="text"], 
  button {
    padding: 1rem; /* Increase padding for better touch targets */
    font-size: 1.1rem; /* Slightly larger font for better readability */
  }

  /* Reduce margins for smaller devices */
  .error-message,
  .success-message {
    font-size: 0.8rem; /* Slightly smaller font size for messages */
  }
}
